.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
}

.App-logo {
  margin-top: 30px;
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Calc {
  margin: auto;
  width: 60%;
  max-width: 550px;
}

.CalcPaper {
  padding: 25px;
  background-color: #e8e8e8;
  font-size: 1.2em;
  font-family: cursive;
}

.Link {
  padding-top: 25px;
}

/* 
  760px - smallest size to fit each full row with values
  278px - smallest size to fit each title of row fully, not with values
*/
@media only screen and (max-width: 760px) {
  .Calc {
    width: 95%;
    min-width: 278px;
  }
}